import update from 'immutability-helper'
import { FETCH_EXISTING_MORTGAGE_REQUEST } from 'v2/spas/owned-property/existing-mortgages/redux/action-types.tsx'
import { FETCH_EXISTING_MORTGAGE_SUCCESS } from 'v2/spas/owned-property/existing-mortgages/redux/action-types.tsx'
import { FETCH_EXISTING_MORTGAGE_FAILURE } from 'v2/spas/owned-property/existing-mortgages/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  existingMortgage: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_EXISTING_MORTGAGE_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_EXISTING_MORTGAGE_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_EXISTING_MORTGAGE_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        existingMortgage: {
          $set: action.payload.existingMortgage,
        }
      })
    }

    default:
      return state
  }
}