import update from 'immutability-helper'
import { FETCH_INVESTMENT_OPPORTUNITY_PLEDGES_REQUEST } from 'v2/spas/investment-opportunity/pledges/redux/action-types.tsx'
import { FETCH_INVESTMENT_OPPORTUNITY_PLEDGES_SUCCESS } from 'v2/spas/investment-opportunity/pledges/redux/action-types.tsx'
import { FETCH_INVESTMENT_OPPORTUNITY_PLEDGES_FAILURE } from 'v2/spas/investment-opportunity/pledges/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  bridgingLoanApplicationInvestmentOpportunity: {},
  pledges: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_INVESTMENT_OPPORTUNITY_PLEDGES_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_INVESTMENT_OPPORTUNITY_PLEDGES_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_INVESTMENT_OPPORTUNITY_PLEDGES_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        bridgingLoanApplicationInvestmentOpportunity: {
          $set: action.payload.bridgingLoanApplicationInvestmentOpportunity,
        },
        pledges: {
          $set: action.payload.bridgingLoanApplicationInvestmentOpportunity.investmentOpportunity.pledges
        },
      })
    }

    default:
      return state
  }
}
