import {combineReducers} from 'redux'
import authentication from './authentication'
import profile from './profile'
import workflow from 'v2/spas/workflow/redux/reducers/view.tsx'
import accountBridgingLoanApplications from 'v2/spas/account/bridging-loan-applications/redux/reducers/index.tsx'
import accountBridgingLoanApplication from 'v2/spas/account/bridging-loan-applications/redux/reducers/view.tsx'
import accountCompany from 'v2/spas/account/companies/redux/reducers/view.tsx'
import accountAccountUser from 'v2/spas/account/account-users/redux/reducers/view.tsx'
import companyDirectors from 'v2/spas/company/directors/redux/reducers/index.tsx'
import companyShareholders from 'v2/spas/company/shareholders/redux/reducers/index.tsx'
import companyOwnedProperties from 'v2/spas/company/owned-properties/redux/reducers/index.tsx'
import accountOwnedProperty from 'v2/spas/account/owned-properties/redux/reducers/view.tsx'
import accountUserOwnedProperties from 'v2/spas/account-user/owned-properties/redux/reducers/index.tsx'
import companyDocuments from 'v2/spas/company/documents/redux/reducers/index.tsx'
import companyDocument from 'v2/spas/company/documents/redux/reducers/view.tsx'
import accountUserDocuments from 'v2/spas/account-user/documents/redux/reducers/index.tsx'
import accountUserDocument from 'v2/spas/account-user/documents/redux/reducers/view.tsx'
import ownedPropertyTenancies from 'v2/spas/owned-property/tenancies/redux/reducers/index.tsx'
import ownedPropertyTenancy from 'v2/spas/owned-property/tenancies/redux/reducers/view.tsx'
import ownedPropertyExistingMortgages from 'v2/spas/owned-property/existing-mortgages/redux/reducers/index.tsx'
import ownedPropertyExistingMortgage from 'v2/spas/owned-property/existing-mortgages/redux/reducers/view.tsx'
import tenancyDocuments from 'v2/spas/tenancy/documents/redux/reducers/index.tsx'
import tenancyDocument from 'v2/spas/tenancy/documents/redux/reducers/view.tsx'
import existingMortgageMortgageAccounts from 'v2/spas/existing-mortgage/mortgage-accounts/redux/reducers/index.tsx'
import existingMortgageMortgageAccount from 'v2/spas/existing-mortgage/mortgage-accounts/redux/reducers/view.tsx'
import existingMortgageDocuments from 'v2/spas/existing-mortgage/documents/redux/reducers/index.tsx'
import existingMortgageDocument from 'v2/spas/existing-mortgage/documents/redux/reducers/view.tsx'
import accountUserActivityFeedItems from 'v2/spas/account-user/activity-feed-items/redux/reducers/index.tsx'
import bridgingLoanApplicationDeal from 'v2/spas/bridging-loan-application/deals/redux/reducers/view.tsx'
import bridgingLoanApplicationProperty from 'v2/spas/bridging-loan-application/properties/redux/reducers/view.tsx'
import bridgingLoanApplicationBridgingLoanApplicants from 'v2/spas/bridging-loan-application/bridging-loan-applicants/redux/reducers/index.tsx'
import companyBridgingLoanApplications from 'v2/spas/company/bridging-loan-applications/redux/reducers/index.tsx'
import accountUserBridgingLoanApplications from 'v2/spas/account-user/bridging-loan-applications/redux/reducers/index.tsx'
import bridgingLoanApplicationInvestmentOpportunity from 'v2/spas/bridging-loan-application/investment-opportunities/redux/reducers/view.tsx'
import investmentOpportunityPresentation from 'v2/spas/investment-opportunity/presentations/redux/reducers/view.tsx'
import investmentOpportunityInvestmentOpportunityInvitations from 'v2/spas/investment-opportunity/investment-opportunity-invitations/redux/reducers/index.tsx'
import investmentOpportunityInvestmentOpportunityInvitation from 'v2/spas/investment-opportunity/investment-opportunity-invitations/redux/reducers/view.tsx'
import investmentOpportunityPledges from 'v2/spas/investment-opportunity/pledges/redux/reducers/index.tsx'

const rootReducer = combineReducers({
  authentication,
  profile,
  workflow,
  accountBridgingLoanApplications,
  accountBridgingLoanApplication,
  accountCompany,
  accountAccountUser,
  companyDirectors,
  companyShareholders,
  companyOwnedProperties,
  accountOwnedProperty,
  accountUserOwnedProperties,
  companyDocuments,
  companyDocument,
  accountUserDocuments,
  accountUserDocument,
  ownedPropertyTenancies,
  ownedPropertyTenancy,
  ownedPropertyExistingMortgages,
  ownedPropertyExistingMortgage,
  tenancyDocuments,
  tenancyDocument,
  existingMortgageMortgageAccounts,
  existingMortgageMortgageAccount,
  existingMortgageDocuments,
  existingMortgageDocument,
  accountUserActivityFeedItems,
  bridgingLoanApplicationProperty,
  bridgingLoanApplicationDeal,
  bridgingLoanApplicationBridgingLoanApplicants,
  companyBridgingLoanApplications,
  accountUserBridgingLoanApplications,
  bridgingLoanApplicationInvestmentOpportunity,
  investmentOpportunityPresentation,
  investmentOpportunityInvestmentOpportunityInvitations,
  investmentOpportunityInvestmentOpportunityInvitation,
  investmentOpportunityPledges,
})

export default rootReducer
