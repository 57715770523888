import update from 'immutability-helper'
import { FETCH_EXISTING_MORTGAGE_DOCUMENTS_REQUEST } from 'v2/spas/existing-mortgage/documents/redux/action-types.tsx'
import { FETCH_EXISTING_MORTGAGE_DOCUMENTS_SUCCESS } from 'v2/spas/existing-mortgage/documents/redux/action-types.tsx'
import { FETCH_EXISTING_MORTGAGE_DOCUMENTS_FAILURE } from 'v2/spas/existing-mortgage/documents/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  existingMortgage: {},
  documents: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_EXISTING_MORTGAGE_DOCUMENTS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_EXISTING_MORTGAGE_DOCUMENTS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_EXISTING_MORTGAGE_DOCUMENTS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        existingMortgage: {
          $set: action.payload.existingMortgage,
        },
        documents: {
          $set: action.payload.existingMortgage.documents
        },
      })
    }

    default:
      return state
  }
}
