import update from 'immutability-helper'
import { FETCH_INVESTMENT_OPPORTUNITY_INVESTMENT_OPPORTUNITY_INVITATIONS_REQUEST } from 'v2/spas/investment-opportunity/investment-opportunity-invitations/redux/action-types.tsx'
import { FETCH_INVESTMENT_OPPORTUNITY_INVESTMENT_OPPORTUNITY_INVITATIONS_SUCCESS } from 'v2/spas/investment-opportunity/investment-opportunity-invitations/redux/action-types.tsx'
import { FETCH_INVESTMENT_OPPORTUNITY_INVESTMENT_OPPORTUNITY_INVITATIONS_FAILURE } from 'v2/spas/investment-opportunity/investment-opportunity-invitations/redux/action-types.tsx'
import { ARCHIVE_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST } from 'v2/spas/investment-opportunity/investment-opportunity-invitations/redux/action-types.tsx'
import { ARCHIVE_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS } from 'v2/spas/investment-opportunity/investment-opportunity-invitations/redux/action-types.tsx'
import { ARCHIVE_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE } from 'v2/spas/investment-opportunity/investment-opportunity-invitations/redux/action-types.tsx'
import { DELETE_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST } from 'v2/spas/investment-opportunity/investment-opportunity-invitations/redux/action-types.tsx'
import { DELETE_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS } from 'v2/spas/investment-opportunity/investment-opportunity-invitations/redux/action-types.tsx'
import { DELETE_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE } from 'v2/spas/investment-opportunity/investment-opportunity-invitations/redux/action-types.tsx'
import { BULK_CREATE_INVESTMENT_OPPORTUNITY_COMMENTS_REQUEST } from 'v2/spas/investment-opportunity/investment-opportunity-invitations/redux/action-types.tsx'
import { BULK_CREATE_INVESTMENT_OPPORTUNITY_COMMENTS_SUCCESS } from 'v2/spas/investment-opportunity/investment-opportunity-invitations/redux/action-types.tsx'
import { BULK_CREATE_INVESTMENT_OPPORTUNITY_COMMENTS_FAILURE } from 'v2/spas/investment-opportunity/investment-opportunity-invitations/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  bridgingLoanApplicationInvestmentOpportunity: {},
  investmentOpportunityInvitations: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_INVESTMENT_OPPORTUNITY_INVESTMENT_OPPORTUNITY_INVITATIONS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_INVESTMENT_OPPORTUNITY_INVESTMENT_OPPORTUNITY_INVITATIONS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_INVESTMENT_OPPORTUNITY_INVESTMENT_OPPORTUNITY_INVITATIONS_SUCCESS: {
      let bridgingLoanApplicationInvestmentOpportunity = action.payload.bridgingLoanApplicationInvestmentOpportunity || {}
      let investmentOpportunity = bridgingLoanApplicationInvestmentOpportunity.investmentOpportunity || {}
      let investmentOpportunityInvitations = investmentOpportunity.investmentOpportunityInvitations || []

      return update(state, {
        status: { $set: 'success' },
        bridgingLoanApplicationInvestmentOpportunity: {
          $set: bridgingLoanApplicationInvestmentOpportunity,
        },
        investmentOpportunityInvitations: {
          $set: investmentOpportunityInvitations
        },
      })
    }

    case ARCHIVE_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST: {
      return state
    }

    case ARCHIVE_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE: {
      return state
    }

    case ARCHIVE_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS: {
      let index = state.investmentOpportunityInvitations.findIndex(x => x.id === action.payload.investmentOpportunityInvitation.id)

      return update(state, {
        investmentOpportunityInvitations: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST: {
      return state
    }
    
    case DELETE_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE: {
      return state
    }
    
    case DELETE_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS: {
      let index = state.investmentOpportunityInvitations.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        investmentOpportunityInvitations: {
          $splice: [[index, 1]],
        },
      })
    }

    case BULK_CREATE_INVESTMENT_OPPORTUNITY_COMMENTS_REQUEST: {
      return state
    }

    case BULK_CREATE_INVESTMENT_OPPORTUNITY_COMMENTS_FAILURE: {
      return state
    }

    case BULK_CREATE_INVESTMENT_OPPORTUNITY_COMMENTS_SUCCESS: {
      return state
    }

    default:
      return state
  }
}
