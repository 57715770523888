import update from 'immutability-helper'
import { FETCH_OWNED_PROPERTY_TENANCIES_REQUEST } from 'v2/spas/owned-property/tenancies/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_TENANCIES_SUCCESS } from 'v2/spas/owned-property/tenancies/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_TENANCIES_FAILURE } from 'v2/spas/owned-property/tenancies/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  ownedProperty: {},
  tenancies: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OWNED_PROPERTY_TENANCIES_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_OWNED_PROPERTY_TENANCIES_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_OWNED_PROPERTY_TENANCIES_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        ownedProperty: {
          $set: action.payload.ownedProperty,
        },
        tenancies: {
          $set: action.payload.ownedProperty.tenancies
        },
      })
    }

    default:
      return state
  }
}
