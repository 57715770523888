import update from 'immutability-helper'
import { FETCH_COMPANY_DOCUMENTS_REQUEST } from 'v2/spas/company/documents/redux/action-types.tsx'
import { FETCH_COMPANY_DOCUMENTS_SUCCESS } from 'v2/spas/company/documents/redux/action-types.tsx'
import { FETCH_COMPANY_DOCUMENTS_FAILURE } from 'v2/spas/company/documents/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  company: {},
  documents: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPANY_DOCUMENTS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_COMPANY_DOCUMENTS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_COMPANY_DOCUMENTS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        company: {
          $set: action.payload.company,
        },
        documents: {
          $set: action.payload.company.documents
        },
      })
    }

    default:
      return state
  }
}
