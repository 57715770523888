import 'bootstrap/dist/css/bootstrap.min.css'
import React, { useEffect, Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import {MortgageBrokerTheme} from 'themes'
import {GlobalStyles} from 'themes'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { HelmetProvider } from "react-helmet-async";
import {Spinner} from 'functional-components'
import { loadStacked } from 'redux/actions/profile/loadStacked'
import { logout } from 'redux/actions/authentication/logout'
import { session } from 'redux/actions/authentication/session'
import { useInterval } from 'functions'
import { BridgingLenderRootPath } from 'functional-components'
import {FetchResource} from 'functional-components'
import {ModalButton} from 'buttons'
import {PageNotFound} from 'pages';
import {ScrollToTop} from 'functional-components'
import {Snackbar} from 'functional-components';
import { ZendeskWidget, PrivateRoute } from 'functional-components';
import {WarningModal} from 'modals'
import { SupportWidget } from 'functional-components';

const Login = lazy(() => import('spas/Login/Login.js'));
const SignInWithMagicLink = lazy(() => import('spas/SignInWithMagicLink/SignInWithMagicLink.tsx'));
const AccountBridgingLoanApplications = lazy(() => import('v2/spas/account/bridging-loan-applications/pages/index.tsx'));
const AccountBridgingLoanApplication = lazy(() => import('v2/spas/account/bridging-loan-applications/pages/view.tsx'));
const Workflow = lazy(() => import('v2/spas/workflow/pages/view.tsx'));
const AccountCompany = lazy(() => import('v2/spas/account/companies/pages/view.tsx'));
const AccountAccountUser = lazy(() => import('v2/spas/account/account-users/pages/view.tsx'));
const CompanyDirectors = lazy(() => import('v2/spas/company/directors/pages/index.tsx'));
const CompanyShareholders = lazy(() => import('v2/spas/company/shareholders/pages/index.tsx'));
const CompanyOwnedProperties = lazy(() => import('v2/spas/company/owned-properties/pages/index.tsx'));
const AccountOwnedProperty = lazy(() => import('v2/spas/account/owned-properties/pages/view.tsx'));
const AccountUserOwnedProperties = lazy(() => import('v2/spas/account-user/owned-properties/pages/index.tsx'));
const CompanyDocuments = lazy(() => import('v2/spas/company/documents/pages/index.tsx'));
const CompanyDocument = lazy(() => import('v2/spas/company/documents/pages/view.tsx'));
const AccountUserDocuments = lazy(() => import('v2/spas/account-user/documents/pages/index.tsx'));
const AccountUserDocument = lazy(() => import('v2/spas/account-user/documents/pages/view.tsx'));
const OwnedPropertyTenancies = lazy(() => import('v2/spas/owned-property/tenancies/pages/index.tsx'));
const OwnedPropertyTenancy = lazy(() => import('v2/spas/owned-property/tenancies/pages/view.tsx'));
const OwnedPropertyExistingMortgages = lazy(() => import('v2/spas/owned-property/existing-mortgages/pages/index.tsx'));
const OwnedPropertyExistingMortgage = lazy(() => import('v2/spas/owned-property/existing-mortgages/pages/view.tsx'));
const TenancyDocuments = lazy(() => import('v2/spas/tenancy/documents/pages/index.tsx'));
const TenancyDocument = lazy(() => import('v2/spas/tenancy/documents/pages/view.tsx'));
const ExistingMortgageMortgageAccounts = lazy(() => import('v2/spas/existing-mortgage/mortgage-accounts/pages/index.tsx'));
const ExistingMortgageMortgageAccount = lazy(() => import('v2/spas/existing-mortgage/mortgage-accounts/pages/view.tsx'));
const ExistingMortgageDocuments = lazy(() => import('v2/spas/existing-mortgage/documents/pages/index.tsx'));
const ExistingMortgageDocument = lazy(() => import('v2/spas/existing-mortgage/documents/pages/view.tsx'));
const AccountUserActivityFeedItems = lazy(() => import('v2/spas/account-user/activity-feed-items/pages/index.tsx'));
const AccountUserActivityFeedItemsArchive = lazy(() => import('v2/spas/account-user/activity-feed-items/pages/archive.tsx'));
const BridgingLoanApplicationDeal = lazy(() => import('v2/spas/bridging-loan-application/deals/pages/view.tsx'));
const BridgingLoanApplicationProperty = lazy(() => import('v2/spas/bridging-loan-application/properties/pages/view.tsx'));
const BridgingLoanApplicationBridgingLoanApplicants = lazy(() => import('v2/spas/bridging-loan-application/bridging-loan-applicants/pages/index.tsx'));
const CompanyBridgingLoanApplications = lazy(() => import('v2/spas/company/bridging-loan-applications/pages/index.tsx'));
const AccountUserBridgingLoanApplications = lazy(() => import('v2/spas/account-user/bridging-loan-applications/pages/index.tsx'));
const BridgingLoanApplicationInvestmentOpportunity = lazy(() => import('v2/spas/bridging-loan-application/investment-opportunities/pages/view.tsx'));
const InvestmentOpportunityPresentation = lazy(() => import('v2/spas/investment-opportunity/presentations/pages/view.tsx'));
const InvestmentOpportunityInvestmentOpportunityInvitations = lazy(() => import('v2/spas/investment-opportunity/investment-opportunity-invitations/pages/index.tsx'));
const InvestmentOpportunityInvestmentOpportunityInvitation = lazy(() => import('v2/spas/investment-opportunity/investment-opportunity-invitations/pages/view.tsx'));
const InvestmentOpportunityInvestmentOpportunityInvitationsArchive = lazy(() => import('v2/spas/investment-opportunity/investment-opportunity-invitations/pages/archive.tsx'));
const InvestmentOpportunityPledges = lazy(() => import('v2/spas/investment-opportunity/pledges/pages/index.tsx'));


function initialiseSentry() {
  if (window.location.href.indexOf("stacked.group") > -1) {
    Sentry.init({
      dsn: "https://d422f667e28646289be0cd3d3c5ac859@o4504261359894528.ingest.sentry.io/4504261362909184",
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }
}

// Trix configuration
Trix.config.blockAttributes.default.tagName = "p"
Trix.config.blockAttributes.default.breakOnReturn = true
Trix.config.attachments.preview.caption.name = false
Trix.config.attachments.preview.caption.size = false

initialiseSentry()

const App = ({
  currentUser,
  currentAccount,
  currentEmailIdentity,
  policy,
  loadStackedStatus,
  loadStackedError,
  sessionStatus,
  sessionError,
  sessionExpiring,
  loadStacked,
  session,
  logout,
}) => {
  const logOut = () => {
    logout(handleLogOut);
  };

  const handleLogOut = response => {
    if (response.status === 'success') {
      window.location.href = '/login';
    } else if (response.status === 'error') {
      window.location.reload();
    }
  };

  useInterval(async () => {
    session();
  }, 600000); // Check every 10 minutes
  
  useEffect(() => {
    loadStacked();
  }, []);

  return (
    <HelmetProvider>
      <MortgageBrokerTheme>
        <Snackbar>
          <GlobalStyles />
          <Router>
            <Suspense fallback={<Spinner />}>
              <SupportWidget link='https://support.stacked.group/knowledge-bases/442e9236-334a-4eb4-a92c-a337e2bd5050' />
              <ScrollToTop />
              <FetchResource status={loadStackedStatus} error={loadStackedError}>
                <WarningModal
                  show={sessionExpiring}
                  heading="Your session is about to expire!">
                  <ModalButton
                    text="Please login again"
                    backgroundColor={props => props.theme.colors.offWhite}
                    color={props => props.theme.colors.black}
                    handleClick={logOut}
                  />
                </WarningModal>
                <WarningModal
                  show={currentUser.signUpStatus === 'confirm_email'}
                  heading="You have not confirmed your email address yet! Please check your emails and click the link to confirm your email address!">
                  <ModalButton
                    text="Continue"
                    backgroundColor={props => props.theme.colors.offWhite}
                    color={props => props.theme.colors.black}
                    handleClick={logOut}
                  />
                </WarningModal>
                <Switch>
                  <Route exact path="/">
                    {BridgingLenderRootPath(currentUser, currentEmailIdentity, currentAccount)}
                  </Route>
                  <Route path="/login">
                    <Login />
                  </Route>
                  <Route path="/sign-in-with-magic-link/:token">
                    <SignInWithMagicLink />
                  </Route>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/bridging-loan-applications"
                    authorised={true}
                  >
                    <AccountBridgingLoanApplications />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/bridging-loan-applications/:bridgingLoanApplicationId"
                    authorised={true}>
                    <AccountBridgingLoanApplication />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/workflows/:workflowId"
                    authorised={true}>
                    <Workflow />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/companies/:companyId"
                    authorised={true}>
                    <AccountCompany />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/account-users/:accountUserId"
                    authorised={true}>
                    <AccountAccountUser />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/companies/:companyId/directors"
                    authorised={true}
                  >
                    <CompanyDirectors />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/companies/:companyId/shareholders"
                    authorised={true}
                  >
                    <CompanyShareholders />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/companies/:companyId/owned-properties"
                    authorised={true}
                  >
                    <CompanyOwnedProperties />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/owned-properties/:ownedPropertyId"
                    authorised={true}>
                    <AccountOwnedProperty />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/account-users/:accountUserId/owned-properties"
                    authorised={true}
                  >
                    <AccountUserOwnedProperties />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/companies/:companyId/documents"
                    authorised={true}
                  >
                    <CompanyDocuments />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/companies/:companyId/documents/:documentId"
                    authorised={true}
                  >
                    <CompanyDocument />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/account-users/:accountUserId/documents"
                    authorised={true}
                  >
                    <AccountUserDocuments />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/account-users/:accountUserId/documents/:documentId"
                    authorised={true}
                  >
                    <AccountUserDocument />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/tenancies"
                    authorised={true}
                  >
                    <OwnedPropertyTenancies />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/tenancies/:tenancyId"
                    authorised={true}
                  >
                    <OwnedPropertyTenancy />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/existing-mortgages"
                    authorised={true}
                  >
                    <OwnedPropertyExistingMortgages />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/existing-mortgages/:existingMortgageId"
                    authorised={true}
                  >
                    <OwnedPropertyExistingMortgage />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/tenancies/:tenancyId/documents"
                    authorised={true}
                  >
                    <TenancyDocuments />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/tenancies/:tenancyId/documents/:documentId"
                    authorised={true}
                  >
                    <TenancyDocument />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/existing-mortgages/:existingMortgageId/mortgage-accounts"
                    authorised={true}
                  >
                    <ExistingMortgageMortgageAccounts />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/existing-mortgages/:existingMortgageId/mortgage-accounts/:mortgageAccountId"
                    authorised={true}
                  >
                    <ExistingMortgageMortgageAccount />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/existing-mortgages/:existingMortgageId/documents"
                    authorised={true}
                  >
                    <ExistingMortgageDocuments />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/existing-mortgages/:existingMortgageId/documents/:documentId"
                    authorised={true}
                  >
                    <ExistingMortgageDocument />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/account-users/:accountUserId/activity-feed-items"
                    authorised={true}>
                    <AccountUserActivityFeedItems />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/account-users/:accountUserId/archived-activity-feed-items"
                    authorised={true}>
                    <AccountUserActivityFeedItemsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/bridging-loan-applications/:bridgingLoanApplicationId/properties/:propertyId"
                    authorised={true}>
                    <BridgingLoanApplicationProperty />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/bridging-loan-applications/:bridgingLoanApplicationId/deals/:dealId"
                    authorised={true}>
                    <BridgingLoanApplicationDeal />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/bridging-loan-applications/:bridgingLoanApplicationId/bridging-loan-applicants"
                    authorised={true}>
                    <BridgingLoanApplicationBridgingLoanApplicants />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/companies/:companyId/bridging-loan-applications"
                    authorised={true}
                  >
                    <CompanyBridgingLoanApplications />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/account-users/:accountUserId/bridging-loan-applications"
                    authorised={true}
                  >
                    <AccountUserBridgingLoanApplications />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/bridging-loan-applications/:bridgingLoanApplicationId/investment-opportunities/:investmentOpportunityId"
                    authorised={true}>
                    <BridgingLoanApplicationInvestmentOpportunity />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/investment-opportunities/:investmentOpportunityId/presentations/:presentationId"
                    authorised={true}>
                    <InvestmentOpportunityPresentation />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/investment-opportunities/:investmentOpportunityId/investment-opportunity-invitations"
                    authorised={true}>
                    <InvestmentOpportunityInvestmentOpportunityInvitations />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/investment-opportunities/:investmentOpportunityId/investment-opportunity-invitations/:investmentOpportunityInvitationId"
                    authorised={true}>
                    <InvestmentOpportunityInvestmentOpportunityInvitation />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/investment-opportunities/:investmentOpportunityId/archived-investment-opportunity-invitations"
                    authorised={true}>
                    <InvestmentOpportunityInvestmentOpportunityInvitationsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/investment-opportunities/:investmentOpportunityId/pledges"
                    authorised={true}>
                    <InvestmentOpportunityPledges />
                  </PrivateRoute>
                  <Route path="*">
                    <PageNotFound />
                  </Route>
                </Switch>
              </FetchResource>
            </Suspense>
          </Router>
        </Snackbar>
      </MortgageBrokerTheme>
    </HelmetProvider>
  );
};

const mapStateToProps = state => ({
  loadStackedStatus: state.profile.loadStackedStatus,
  loadStackedError: state.profile.loadStackedError,
  currentUser: state.profile.currentUser,
  currentEmailIdentity: state.profile.currentEmailIdentity,
  currentAccount: state.profile.currentAccount,
  policy: state.profile.policy,
  sessionStatus: state.authentication.sessionStatus,
  sessionError: state.authentication.sessionError,
  sessionExpiring: state.authentication.sessionExpiring,
});

const mapDispatchToProps = {
  loadStacked: loadStacked,
  session: session,
  logout: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);