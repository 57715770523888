import update from 'immutability-helper'
import { FETCH_OWNED_PROPERTY_EXISTING_MORTGAGES_REQUEST } from 'v2/spas/owned-property/existing-mortgages/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_EXISTING_MORTGAGES_SUCCESS } from 'v2/spas/owned-property/existing-mortgages/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_EXISTING_MORTGAGES_FAILURE } from 'v2/spas/owned-property/existing-mortgages/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  ownedProperty: {},
  existingMortgages: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OWNED_PROPERTY_EXISTING_MORTGAGES_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_OWNED_PROPERTY_EXISTING_MORTGAGES_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_OWNED_PROPERTY_EXISTING_MORTGAGES_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        ownedProperty: {
          $set: action.payload.ownedProperty,
        },
        existingMortgages: {
          $set: action.payload.ownedProperty.existingMortgages
        },
      })
    }

    default:
      return state
  }
}
