export const FETCH_INVESTMENT_OPPORTUNITY_INVESTMENT_OPPORTUNITY_INVITATIONS_REQUEST = 'FETCH_INVESTMENT_OPPORTUNITY_INVESTMENT_OPPORTUNITY_INVITATIONS_REQUEST'
export const FETCH_INVESTMENT_OPPORTUNITY_INVESTMENT_OPPORTUNITY_INVITATIONS_SUCCESS = 'FETCH_INVESTMENT_OPPORTUNITY_INVESTMENT_OPPORTUNITY_INVITATIONS_SUCCESS'
export const FETCH_INVESTMENT_OPPORTUNITY_INVESTMENT_OPPORTUNITY_INVITATIONS_FAILURE = 'FETCH_INVESTMENT_OPPORTUNITY_INVESTMENT_OPPORTUNITY_INVITATIONS_FAILURE'

export const FETCH_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST = 'FETCH_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST'
export const FETCH_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS = 'FETCH_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS'
export const FETCH_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE = 'FETCH_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE'

export const CREATE_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST = 'CREATE_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST'
export const CREATE_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS = 'CREATE_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS'
export const CREATE_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE = 'CREATE_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE'

export const ARCHIVE_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST = 'ARCHIVE_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST'
export const ARCHIVE_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS = 'ARCHIVE_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS'
export const ARCHIVE_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE = 'ARCHIVE_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE'

export const UNARCHIVE_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST = 'UNARCHIVE_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST'
export const UNARCHIVE_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS = 'UNARCHIVE_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS'
export const UNARCHIVE_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE = 'UNARCHIVE_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE'

export const DELETE_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST = 'DELETE_INVESTMENT_OPPORTUNITY_INVITATION_REQUEST'
export const DELETE_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS = 'DELETE_INVESTMENT_OPPORTUNITY_INVITATION_SUCCESS'
export const DELETE_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE = 'DELETE_INVESTMENT_OPPORTUNITY_INVITATION_FAILURE'

export const BULK_CREATE_INVESTMENT_OPPORTUNITY_COMMENTS_REQUEST = 'BULK_CREATE_INVESTMENT_OPPORTUNITY_COMMENTS_REQUEST'
export const BULK_CREATE_INVESTMENT_OPPORTUNITY_COMMENTS_SUCCESS = 'BULK_CREATE_INVESTMENT_OPPORTUNITY_COMMENTS_SUCCESS'
export const BULK_CREATE_INVESTMENT_OPPORTUNITY_COMMENTS_FAILURE = 'BULK_CREATE_INVESTMENT_OPPORTUNITY_COMMENTS_FAILURE'