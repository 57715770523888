import {LOAD_STACKED_REQUEST} from 'redux/actionTypes.js'
import {LOAD_STACKED_SUCCESS} from 'redux/actionTypes.js'
import {LOAD_STACKED_FAILURE} from 'redux/actionTypes.js'
import axios from 'axios'
import Cookies from 'js-cookie'

export function loadStacked() {
  return function (dispatch) {
    dispatch(loadStackedRequest())

    let query = `
      {
        currentUser {
          id
          signUpStatus
          termsAcceptedAt
          consentedToProcessPropertyInvestmentDataAt
          signUpDetail {
            marketingSource
            promotionCode{
              id
              interval
              numberOfIntervals
            }
          }
          currentAccountUser {
            id
            firstName
            lastName
            fullName
            avatarUrl
            avatarType
            policy
            layoutPreferences
            counters
            privateInvestor {
              id
            }
            propertyInvestor {
              id
            }
            tenant {
              id
            }
            guarantor {
              id
            }
            mortgageBroker {
              id
            }
            workflows {
              id
            }
          }
          currentAccount {
            id
            name
            logoUrl
            typeName
            counters
            subscription {
              trialStart
              trialEnd
              status
            }
            mortgageBrokerAccount {
              id
              counters
            }
            bridgingLenderAccount {
              id
              counters
            }
          }
          emailIdentity {
            id
            email
          }
          policy {
            canSeeAdminInterface
            canEditUserInfo
            canEditAccountInfo
            canManageTeam
            canViewProspectiveProperties
            canViewDeals
            canManagePrivateFinanceProcesses
            canManagePublicFinanceProcesses
            canManagePortfolio
            canSeeMortgageBrokerInterface
          }
        }
      }
    `

    return axios({
      url: process.env.REACT_APP_API_PATH + 'graphql',
      method: 'post',
      headers: {
        Authorization: Cookies.get('jwtToken'),
      },
      data: {
        query: query,
      },
    })
      .then(res => {
        if (res.data.errors) {
          dispatch(loadStackedFailure(res.data.errors[0].message))
          Cookies.remove('jwtToken')
        } else {
          dispatch(loadStackedSuccess(res.data.data.currentUser))
        }
      })
      .catch(error => {
        dispatch(loadStackedFailure(error.message))
        Cookies.remove('jwtToken')
      })
  }
}

export const loadStackedRequest = () => ({
  type: LOAD_STACKED_REQUEST,
})

export const loadStackedSuccess = currentUser => ({
  type: LOAD_STACKED_SUCCESS,
  payload: {
    currentUser: currentUser,
  },
})

export const loadStackedFailure = error => ({
  type: LOAD_STACKED_FAILURE,
  payload: {
    error: error,
  },
})
