import React from 'react'
import { CardGrid } from 'grids'
import Col from 'react-bootstrap/Col'
import { GridSectionHeading } from 'headings'
import { AssociationCard } from 'cards'
import { Bank } from 'react-bootstrap-icons';
import { useCurrentAccount } from 'functions'

function Grid() {

  let currentAccount = useCurrentAccount()
  let bridgingLenderAccount = currentAccount.bridgingLenderAccount || {}

  let counters = bridgingLenderAccount.counters || {}
  let bridgingLoanApplicationsCounter = counters.bridgingLoanApplications || {}
  
  return (
    <CardGrid>
      <Col lg='12'>
        <GridSectionHeading
          heading='What do you want to do?'
          description='Here are some helpful shortcuts, to point you in the right direction'
        />
      </Col>

      <Col xs='6' lg='3' id='association-grid-card'>
        <AssociationCard
          link={`/accounts/${currentAccount.id}/bridging-loan-applications`}
          heading='Bridging loan applications'
          icon={<Bank size={50} />}
          description='Review your outstanding bridging applications'
          count={bridgingLoanApplicationsCounter.active}
        />
      </Col>
    </CardGrid>
  )
}

export default Grid